export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {

    $(document).ready(function () {
      $('.js-burger').on('click', function(){
        $('.mobile-nav').collapse('toggle');
      });
    });
  },
};
